.dndflow {
  flex-direction: column;
  display: flex;
  flex-grow: 1;
  height: 100%;
  z-index: 0;
}



.dndnode.input {
  border-color: #0041d0;
}

.dndnode.output {
  border-color: #ff0072;
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 100%;
}

.dndflow .selectall {
  margin-top: 10px;
}

@media screen and (min-width: 468px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    width: 20%;
    max-width: 250px;
  }
}

