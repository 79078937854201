html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: monospace, sans-serif !important;
}

.app{
  height: 100%;
  display: flex;
  position: relative
}


::-webkit-scrollbar{
  width: 10px;

}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-track:hover{
  background: #555;
}
