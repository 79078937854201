body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

/* This will help identify if any MUI styles are overriding our scrolling */
.MuiAccordion-root, .MuiAccordionDetails-root {
  max-height: none !important;
  overflow: visible !important;
}

body {
  overflow: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
}

/* Add to your CSS file */
.operator-scroller {
  overflow-y: auto !important;
}