@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";

@import "pipelinediagram";
@import "Home.module";
@import "globals";

/* Home page specific scrolling */
.home-page-container .team-page-container .space-editor-page-container {
  height: auto !important;
  min-height: 100vh;
  overflow-y: auto !important;
}

/* Override parent overflow settings when inside home page */
html:has(.home-page-container), 
body:has(.home-page-container), 
html:has(.team-page-container), 
body:has(.team-page-container), 
html:has(.space-editor-page-container), 
body:has(.space-editor-page-container) {
  overflow: auto !important;
  height: auto !important;
}




input,
textarea {
    font-family: monospace, sans-serif;
}

.form-container {
    width: 15%;
    max-height: 400px; /* Adjust this value as needed */
    overflow-y: auto; /* Add scrolling if needed */
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust gap between elements as needed */
    z-index: 10;
    top: 1vh;
}

.right-align {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 15%;
    position: absolute;
    right: 0;
    top: 10%; /* Adjust as necessary */
}

.operator-selector {
    position: fixed;
    top: 0;
    left: 60px; /* Adjust according to the sidebar's collapsed width */
    width: 180px; /* Adjust as needed */
    height: 100vh;
    overflow-y: auto;
    background-color: white;
    z-index: 100;
    transition: left 0.3s ease; /* For smooth transition with sidebar */
}

.operator-selector.collapsed {
    left: 60px; /* Sidebar collapsed width */
}

.operator-selector.expanded {
    left: 240px; /* Sidebar expanded width */
}


#action-feed-container {
    height: 100vh;
    overflow-y: auto;
}

#action-feed-button {
    position: absolute;
    top: 2vh;
    left: 35vw;
}

#edge-data-popup {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow-y: auto;
    background-color: white;
    box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1);
    z-index: 20000;
}

.ag-theme-alpine .ag-row {
    height: 25px !important; /* Adjust the height as necessary */
}

.ag-theme-alpine .ag-cell {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 1px !important;
    padding-right: 1px !important;
    display: flex;
    align-items: center;
    justify-content: left;
}

.ag-theme-alpine .ag-header-cell {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    padding-left: 3px !important;
    padding-right: 1px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contextMenuItem {
    padding: 8px 12px;
    cursor: pointer;
}

.contextMenuItem:hover {
    background-color: #f0f0f0;
}

.react-flow__controls-interaction {
    display: none;
}